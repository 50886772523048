import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { ResourceType } from 'src/app/models/enums';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private _httpClient: HttpClient) {}

  public get(
    resourceType: ResourceType,
    resourceId: number,
    fileName: string
  ): Observable<Uint8Array> {
    return this._httpClient
      .get(
        `${environment.clientApi}/files/${resourceType}/${resourceId}/${fileName}`,
        { responseType: 'arraybuffer' }
      )
      .pipe(
        map((response) => new Uint8Array(response)),
        take(1)
      );
  }

  public save(
    resourceType: ResourceType,
    resourceId: number,
    fileName: string,
    file: File
  ): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this._httpClient
      .post(
        `${environment.clientApi}/files/${resourceType}/${resourceId}/${fileName}`,
        formData,
        {
          responseType: 'text',
        }
      )
      .pipe(take(1));
  }

  public delete(
    resourceType: ResourceType,
    resourceId: number,
    fileName: string
  ): Observable<string> {
    return this._httpClient
      .delete(
        `${environment.clientApi}/files/${resourceType}/${resourceId}/${fileName}`,
        {
          responseType: 'text',
        }
      )
      .pipe(take(1));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SocketEvent } from 'src/app/models/socket/socket-event';
import { WebsocketAction } from 'src/app/models/socket/websocket-action';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UserEnvironmentService } from '../user-environment/user-environment.service';

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {
  private _socket: WebSocket;
  private _messages = new Subject<SocketEvent>();
  private _tableIds = new Set<number>();
  private _tableIdsObservable = new BehaviorSubject<number[]>([]);
  private _messageQueue: string[] = [];
  private _isAuthenticated = false;
  private _isSocketConnected = false;

  constructor(
    private _userEnvironmentService: UserEnvironmentService,
    private _localStorageService: LocalStorageService
  ) {}

  public get messages(): Observable<SocketEvent> {
    return this._messages.asObservable();
  }

  public get tableIdsObservable(): Observable<number[]> {
    return this._tableIdsObservable.asObservable();
  }

  public openConnection(): void {
    if (this._isSocketConnected) return;

    console.log(`Try to open socket connection`);
    this._isSocketConnected = true;
    this._socket = new WebSocket(environment.socketUrl);
    this.listenToSocketEvents();
  }

  private listenToSocketEvents(): void {
    this.onOpen();
    this.onMessage();
    this.onClose();
    this.onError();
  }

  private onOpen(): void {
    this._socket.onopen = (e) => {
      console.log('[OPEN] Connection established');
      this.authenticateUser();
    };
  }

  private onMessage(): void {
    this._socket.onmessage = (event) => {
      console.log(`[message] Data received from server: ${event.data}`);
      const data = JSON.parse(event.data) as SocketEvent;
      if (data.type !== 'INFO') {
        this._messages.next(data);
      } else if (data.data === 'AUTHENTICATED') {
        console.log('User was successfully authenticated');
        this._isAuthenticated = true;
        this.sendMessagesFromQueue();
      }
    };
  }

  private onClose(): void {
    this._socket.onclose = (event) => {
      if (event.wasClean) {
        console.warn(
          `[CLOSE] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.warn('[close] Connection died', this._socket.readyState);
      }
    };
  }

  private onError(): void {
    this._socket.onerror = (error) => {
      console.error(`[ERROR] Socket error: `, error);
    };
  }

  private authenticateUser(): void {
    const storageKey = this._userEnvironmentService.getUserLocalKey();
    const localUser = this._localStorageService.getData(storageKey);
    if (localUser) {
      const localUserObject = JSON.parse(localUser);
      this.sendMessage('AUTHENTICATE', localUserObject.jwt);
      console.log('Send authenticate message with jwt');
    } else {
      console.error('The user is not authenticated');
    }
  }

  private sendMessagesFromQueue(): void {
    if (this._messageQueue.length === 0) return;

    console.log(
      `Send messages to subscribe to topics, number of topics: ${this._messageQueue.length}`
    );
    this._messageQueue.forEach((message) => {
      this._socket.send(message);
    });
    this._messageQueue = [];
    console.log(`Message queue was cleaned successfully`);
  }

  public subscribeToTableId(id: number, selectedRoleId: number): void {
    this.subscribeToTableIds([id], selectedRoleId);
  }

  public subscribeToTableIds(ids: number[], selectedRoleId: number): void {
    const subscriptions = this.getSubscriptionsMapping('TABLE', ids);
    this.tryToSendMessage(JSON.stringify({ subscriptions }));

    this.addToLocalStorage(ids, selectedRoleId);
  }

  private getSubscriptionsMapping(
    topic: string,
    ids: number[]
  ): { topic: string; id: number }[] {
    return ids.map((id) => ({
      topic,
      id,
    }));
  }

  private addToLocalStorage(ids: number[], selectedRoleId: number): void {
    ids.forEach((id) => {
      this._tableIds.add(id);
    });

    this.updateLocalStorage(selectedRoleId);
  }

  public unsubscribeToTableId(id: number, selectedRoleId: number): void {
    this.unsubscribeToTableIds([id], selectedRoleId);
  }

  public unsubscribeToTableIds(ids: number[], selectedRoleId: number): void {
    const subscriptions = this.getSubscriptionsMapping('TABLE', ids);
    this.sendMessage('UNSUBSCRIBE_TOPICS', JSON.stringify({ subscriptions }));

    this.removeFromLocalStorage(ids, selectedRoleId);
  }

  private removeFromLocalStorage(ids: number[], selectedRoleId: number): void {
    ids.forEach((id) => {
      this._tableIds.delete(id);
    });

    this.updateLocalStorage(selectedRoleId);
  }

  private updateLocalStorage(selectedRoleId: number): void {
    const tableIds = Array.from(this._tableIds);
    const tableIdValues = this._tableIdsObservable.getValue();

    if (
      tableIds.length === tableIdValues.length &&
      tableIds.every((tableId) => tableIdValues.includes(tableId))
    ) {
      return;
    }

    this._tableIdsObservable.next(tableIds);

    this._localStorageService.saveData(
      LocalStorageKeys.SubscribedTableIds + selectedRoleId,
      JSON.stringify(tableIds)
    );
  }

  public subscribeToOrderIds(ids: number[]): void {
    const subscriptions = this.getSubscriptionsMapping('ORDER', ids);
    this.tryToSendMessage(JSON.stringify({ subscriptions }));
  }

  public unsubscribeToOrderIds(ids: number[]): void {
    const subscriptions = this.getSubscriptionsMapping('ORDER', ids);
    this.sendMessage('UNSUBSCRIBE_TOPICS', JSON.stringify({ subscriptions }));
  }

  private tryToSendMessage(message: string): void {
    const data = JSON.stringify({
      type: 'SUBSCRIBE_TOPICS',
      message,
    });

    if (this._isAuthenticated) {
      console.log(
        `Send socket message <${message}> on topic <SUBSCRIBE_TOPICS>`
      );
      this._socket.send(data);
    } else {
      this._messageQueue.push(data);
    }
  }

  private sendMessage(action: WebsocketAction, message: string): void {
    const data = JSON.stringify({
      type: action,
      message,
    });
    console.log(`Send socket message <${message}> on topic <${action}>`);
    this._socket.send(data);
  }

  public closeConnection(): void {
    this._isSocketConnected = false;
    this._isAuthenticated = false;
    this._tableIds.clear();
    this._tableIdsObservable.next([]);
    console.log(`[CLOSE] Socket connection`);
    this._socket?.close();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AccountShort } from 'src/app/models/entities/account-short';
import { RestaurantRole, roleHierarchy } from 'src/app/models/enums';
import { JwtData } from 'src/app/models/jwt-data';
import { Role } from 'src/app/models/role';
import { FileName } from 'src/app/utils/constants/file-name';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';
import { ImageFunctions } from 'src/app/utils/functions/image-functions';
import { environment } from 'src/environments/environment';
import { AccountApiService } from '../api/account-api.service';
import { AuthApiService } from '../api/auth-api.service';
import { FileService } from '../api/file.service';
import { LocalStorageService } from '../common/local-storage/local-storage.service';
import { Logger } from '../common/logging/logger';
import { LoggerService } from '../common/logging/logger.service';
import { SocketClientService } from '../common/socket-client/socket-client.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _logger: Logger;
  private _account = new BehaviorSubject<AccountShort | undefined>(undefined);
  private _selectedRole = new BehaviorSubject<Role | undefined>(undefined);
  private _isReady = new BehaviorSubject<boolean>(false);
  private _renewJwtTimeout: NodeJS.Timeout;

  public profilePicture = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private _loggerService: LoggerService,
    private _router: Router,
    private _fileService: FileService,
    private _accountApiService: AccountApiService,
    private _authApiService: AuthApiService,
    private _localStorageService: LocalStorageService,
    private _socketClientService: SocketClientService
  ) {
    this._logger = this._loggerService.getService(AccountService.name);
    this.initialize();
  }

  public get account(): Observable<AccountShort | undefined> {
    return this._account.asObservable();
  }

  public get selectedRole(): Observable<Role | undefined> {
    return this._selectedRole.asObservable();
  }

  public get isAuthenticated(): boolean {
    return this._account.getValue() !== undefined;
  }

  public get isRoleSelected(): boolean {
    return this._selectedRole.getValue() !== undefined;
  }

  public get isRestaurantSetted(): boolean {
    return this._selectedRole.getValue()?.restaurantId !== undefined;
  }

  public get isReady(): Observable<boolean> {
    return this._isReady.asObservable();
  }

  public initialize(): Promise<void> {
    const localCurrentUser = this._localStorageService.getData(
      LocalStorageKeys.CurrentUser
    );
    if (!localCurrentUser) {
      this._isReady.next(true);
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      this._accountApiService
        .read()
        .pipe(take(1))
        .subscribe({
          next: (acc) => {
            this.getSelectedRole(acc);
            this.onAccountReceived(acc);
            this._isReady.next(true);
            resolve();
          },
          error: (error) => {
            this._isReady.next(true);
            reject(error);
          },
        });
    });
  }

  private getSelectedRole(account: AccountShort): void {
    const selectedRoleIdString = this._localStorageService.getData(
      LocalStorageKeys.SelectedRoleId
    );
    if (!selectedRoleIdString) return;

    const selectedRoleId = JSON.parse(selectedRoleIdString);
    const selectedRole = account.roles.find(
      (role) => role.id === selectedRoleId
    );
    if (selectedRole) {
      this.onSelectedRole(selectedRole);
    }
  }

  public refreshAccount(redirectOnFailed: boolean = false): Promise<void> {
    this._logger.info('Renew JWT was invokec.');

    return new Promise((resolve, reject) => {
      this._authApiService
        .renewJwt()
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this._localStorageService.saveData(
              LocalStorageKeys.CurrentUser,
              JSON.stringify(data)
            );
            this.updateAccount(redirectOnFailed);
            resolve();
          },
          error: () => {
            console.error('Error on update token');
            reject();
          },
        });
    });
  }

  public updateAccount(redirectOnFailed: boolean = false): void {
    this._accountApiService
      .read()
      .pipe(take(1))
      .subscribe({
        next: (acc) => this.onAccountReceived(acc),
        error: (error) => {
          console.error('Could not get account information: ', error);
          this._account.next(undefined);
          this.profilePicture.next(undefined);
          this._localStorageService.removeData(LocalStorageKeys.SelectedRoleId);
          if (redirectOnFailed) {
            this._router.navigate(['auth', 'login']);
          }
        },
      });
  }

  private onAccountReceived(account: AccountShort): void {
    this._account.next(account);

    if (account?.id) {
      this.renewJwtWhenRequired();
      this.getProfilePicture();
    }

    if (!this._selectedRole.getValue()) return;

    const id = this._selectedRole.getValue()?.id;
    const selectedRoles = account.roles.filter((r) => r.id === id);
    if (!selectedRoles || selectedRoles.length < 1) return;

    this.onSelectedRole(selectedRoles[0]);
  }

  private renewJwtWhenRequired(): void {
    this.clearRenewJwtTimeout();

    const localCurrentUser = this._localStorageService.getData(
      LocalStorageKeys.CurrentUser
    );
    if (!localCurrentUser) return;

    const currentUser = JSON.parse(localCurrentUser) as JwtData;
    if (!currentUser?.expirationTimestamp) return;

    const currentTimestampInSeconds = moment.utc().valueOf() / 1000;
    const whenRenewIsRequired =
      currentUser.expirationTimestamp - environment.renewJwtTimeoutInSeconds;

    if (currentTimestampInSeconds >= whenRenewIsRequired) {
      this.refreshAccount();
    } else {
      const timeoutToUpdateJwt =
        (whenRenewIsRequired - currentTimestampInSeconds) * 1000;
      this._renewJwtTimeout = setTimeout(
        () => this.refreshAccount(),
        timeoutToUpdateJwt
      );
    }
  }

  private clearRenewJwtTimeout(): void {
    if (this._renewJwtTimeout) {
      clearTimeout(this._renewJwtTimeout);
    }
  }

  public onSelectedRole(role: Role | undefined): void {
    if (role) {
      this.setSelectedRole(role);
    } else if (this._selectedRole.getValue() !== undefined) {
      this._localStorageService.removeData(LocalStorageKeys.SelectedRoleId);
      this._selectedRole.next(undefined);
      this.refreshAccount();
    }
  }

  private setSelectedRole(role: Role) {
    this._localStorageService.saveData(
      LocalStorageKeys.SelectedRoleId,
      `${role.id}`
    );

    this._authApiService.loginRole(role.restaurantId!, role.role).subscribe({
      next: (data) => {
        this._localStorageService.saveData(
          LocalStorageKeys.CurrentUser,
          JSON.stringify(data)
        );
        this._selectedRole.next(role);
        this.renewJwtWhenRequired();
      },
      error: (error) => {
        this._logger.error(
          `Could not update the JWT for restaurant id <${role.restaurantId}> and role <${role.role}>: `,
          error.error
        );
      },
    });
  }

  public hasRole(role: RestaurantRole): boolean {
    if (!this._selectedRole?.getValue()) {
      return false;
    }

    const selectedRole = this._selectedRole.getValue()!;
    return roleHierarchy[role].includes(selectedRole.role);
  }

  private getProfilePicture(): void {
    this._fileService
      .get('ACCOUNT', this._account.getValue()!.id, FileName.ProfilePicture)
      .subscribe({
        next: (picture) => {
          this.updateProfilePicture(picture);
        },
        error: (_error) => {
          console.info('Account does not have an picture yet');
        },
      });
  }

  private updateProfilePicture(image: Uint8Array): void {
    const fileType = ImageFunctions.detectImageType(image);
    if (!fileType) return;

    const blob = new Blob([image], { type: fileType });
    const reader = new FileReader();
    reader.onload = () => {
      const profilePicture = reader.result as string;
      this.profilePicture.next(profilePicture);
    };
    reader.readAsDataURL(blob);
  }

  public logout(): void {
    this.clearRenewJwtTimeout();
    this._socketClientService.closeConnection();
    this._localStorageService.removeData(LocalStorageKeys.CurrentUser);
    this._localStorageService.removeData(LocalStorageKeys.SelectedRoleId);
    this._account.next(undefined);
    this._selectedRole.next(undefined);
    this.profilePicture.next(undefined);
  }
}

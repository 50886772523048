<div class="notifications">
  <div
    *ngFor="let notification of notifications"
    class="notification"
    [ngClass]="getClassName(notification)"
  >
    <ng-container
      *ngTemplateOutlet="
        notificationTpl;
        context: { notification: notification }
      "
    ></ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification">
  <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{ notification.title | translate | async }}</div>
    <i (click)="close(notification)" class="bi bi-x-circle"></i>
  </div>

  <div class="message">{{ notification.message | translate | async }}</div>
</ng-template>

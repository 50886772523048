import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserEnvironmentService {
  constructor(private _router: Router) {}

  public getUserLocalKey(): string {
    return this._router.url.startsWith(environment.restaurantStartUrl)
      ? LocalStorageKeys.CurrentClient
      : LocalStorageKeys.CurrentUser;
  }
}

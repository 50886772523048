export class ImageFunctions {
  public static getImageFromUint8Array(
    image: Uint8Array,
    callback: (image: string) => void
  ): void {
    const fileType = ImageFunctions.detectImageType(image);
    if (!fileType) return;

    const blob = new Blob([image], { type: fileType });
    const reader = new FileReader();
    reader.onload = () => {
      callback(reader.result as string);
    };
    reader.readAsDataURL(blob);
  }

  public static detectImageType(image: Uint8Array): string | null {
    if (image[0] === 0xff && image[1] === 0xd8) {
      return 'image/jpeg';
    }

    if (
      image[0] === 0x89 &&
      image[1] === 0x50 &&
      image[2] === 0x4e &&
      image[3] === 0x47
    ) {
      return 'image/png';
    }

    if (image[0] === 0x47 && image[1] === 0x49 && image[2] === 0x46) {
      return 'image/gif';
    }

    // Add more checks for other formats as needed

    return null;
  }
}

export type RestaurantRole =
  | 'ADMIN'
  | 'OWNER'
  | 'MANAGER'
  | 'COOK'
  | 'WAITER'
  | 'CLIENT';

export const roleHierarchy: Record<RestaurantRole, RestaurantRole[]> = {
  ADMIN: ['ADMIN'],
  OWNER: ['ADMIN', 'OWNER'],
  MANAGER: ['ADMIN', 'OWNER', 'MANAGER'],
  COOK: ['ADMIN', 'OWNER', 'MANAGER', 'COOK'],
  WAITER: ['ADMIN', 'OWNER', 'MANAGER', 'WAITER'],
  CLIENT: ['ADMIN', 'OWNER', 'MANAGER', 'COOK', 'WAITER', 'CLIENT'],
};

export type ResourceType =
  | 'ACCOUNT'
  | 'RESTAURANT'
  | 'MENU'
  | 'PRODUCT'
  | 'INGREDIENT'
  | 'ALLERGEN'
  | 'CHARACTERISTIC';

export type Status =
  | 'PLACED'
  | 'SUBMITTED'
  | 'ACTIVE'
  | 'CLOSED'
  | 'PENDING'
  | 'COMPLETED'
  | 'PREPARING'
  | 'PREPARED'
  | 'SERVING'
  | 'WAITING'
  | 'BUSY'
  | 'FREE';

import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private _loggingService: LoggingService) {}

  public getService(className: string): Logger {
    return new Logger(this._loggingService, className);
  }
}

import { Injectable } from '@angular/core';
import { ClientApiService } from './client-api.service';
import { Observable } from 'rxjs';
import { Restaurant } from 'src/app/models/restaurant/restaurant';
import { RestaurantMetadata } from 'src/app/models/client/restaurant-metadata';

@Injectable({
  providedIn: 'root',
})
export class RestaurantApiService {
  private static apiUrl = 'restaurant';

  constructor(private _clientApiService: ClientApiService) {}

  public read(restaurantId: number): Observable<Restaurant> {
    return this._clientApiService.post<Restaurant>(
      `${RestaurantApiService.apiUrl}/read`,
      { id: restaurantId }
    );
  }

  public readShort(restaurantId: number): Observable<Restaurant> {
    return this._clientApiService.post<Restaurant>(
      `${RestaurantApiService.apiUrl}/read/short`,
      { id: restaurantId }
    );
  }

  public save(restaurant: Restaurant): Observable<number> {
    return this._clientApiService.post<number>(
      `${RestaurantApiService.apiUrl}/create`,
      restaurant
    );
  }

  public update(restaurant: Restaurant): Observable<Restaurant> {
    return this._clientApiService.put<Restaurant>(
      `${RestaurantApiService.apiUrl}/update`,
      restaurant
    );
  }

  public isUrlPathValid(urlPath: string): Observable<boolean> {
    return this._clientApiService.post<boolean>(
      `${RestaurantApiService.apiUrl}/validate/path`,
      urlPath
    );
  }

  public readMetadataByTableId(id: number): Observable<RestaurantMetadata> {
    return this._clientApiService.post<RestaurantMetadata>(
      `${RestaurantApiService.apiUrl}/read/table/id/metadata`,
      { id }
    );
  }
}

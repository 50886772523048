import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { skipWhile } from 'rxjs';
import { AccountService } from '../../services/online-menu/account.service';

export const authGuard: CanActivateFn = () => {
  const accountService = inject(AccountService);
  const router = inject(Router);

  return new Promise<boolean>((resolve) => {
    accountService.isReady.pipe(skipWhile((data) => !data)).subscribe({
      next: () => {
        if (accountService.isAuthenticated) {
          return resolve(true);
        }

        router.navigate(['auth', 'login']);
        return resolve(false);
      },
    });
  });
};

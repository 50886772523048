import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { LogLevel } from 'src/app/models/enums/log-level';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingApiService {
  constructor(private _clientApiService: ClientApiService) {}

  public logMessage(logLevel: LogLevel, message: string): void {
    this._clientApiService
      .post<any>(`${logLevel}/log`, message)
      .pipe(take(1))
      .subscribe();
  }
}

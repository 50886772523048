import { TranslateLoader } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { ClientApiService } from './client-api.service';
import { TranslationMessage } from 'src/app/models/translation-message';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private _clientApiService: ClientApiService) {}

  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      this._clientApiService
        .post<TranslationMessage[]>('message/read/lang', {
          language: lang.toLocaleUpperCase(),
        })
        .pipe(take(1))
        .subscribe({
          next: (messages) => {
            const translation = this.mapErrors(messages);
            observer.next(translation);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  private mapErrors(messages: TranslationMessage[]): any {
    const translation: any = {};
    messages.forEach((message) => {
      translation[message.key] = message.value;
    });

    return translation;
  }
}

export class LocalStorageKeys {
  public static DefaultLanguage = 'DefaultLanguage';
  public static SelectedRoleId = 'SelectedRoleId';
  public static SubscribedTableIds = 'SubscribedTableIds';
  public static CurrentUser = 'CurrentUser';
  public static CurrentClient = 'CurrentClient';
  public static CurrentRestaurantId = 'CurrentRestaurantId';
  public static BucketList = 'BucketListForRestaurantId';
  public static OrderList = 'OrderListForRestaurantId';
  public static ManagerSideBar = 'ManagerSideBar';
}

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationModule } from './components/notification/notification.module';
import { ClientApiService } from './services/api/client-api.service';
import { CustomTranslateLoader } from './services/api/custom-translate-loader';
import { OmMissingTranslationHandler } from './shared/handlers/om-missing-translation-handler';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.countryDefaultLanguage,
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [ClientApiService],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: OmMissingTranslationHandler,
            },
            useDefaultLang: false,
        }),
        NotificationModule,
        BrowserAnimationsModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { JwtData } from 'src/app/models/jwt-data';
import { LocalStorageService } from 'src/app/services/common/local-storage/local-storage.service';
import { Logger } from 'src/app/services/common/logging/logger';
import { LoggerService } from 'src/app/services/common/logging/logger.service';
import { UserEnvironmentService } from 'src/app/services/common/user-environment/user-environment.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private _logger: Logger;
  private _lastJwtExpirationTime: number | undefined;

  constructor(
    private _loggerService: LoggerService,
    private _userEnvironmentService: UserEnvironmentService,
    private _localStorageService: LocalStorageService
  ) {
    this._logger = this._loggerService.getService(JwtInterceptor.name);
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/log')) {
      return next.handle(request);
    }

    const storageKey = this._userEnvironmentService.getUserLocalKey();
    const localUser = this._localStorageService.getData(storageKey);
    if (localUser) {
      return this.handleCurrentUser(request, next, localUser, storageKey);
    }

    return next.handle(request);
  }

  private handleCurrentUser(
    request: HttpRequest<any>,
    next: HttpHandler,
    localUser: string,
    key: string
  ): Observable<HttpEvent<any>> {
    let currentUser = JSON.parse(localUser) as JwtData;
    if (!currentUser?.jwt) {
      this._localStorageService.removeData(key);
      return next.handle(request);
    }

    if (this.isJwtValid(currentUser)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwt}`,
        },
      });
    } else {
      this.logExpiredToken(currentUser);
      this._localStorageService.removeData(key);
    }

    return next.handle(request);
  }

  private isJwtValid(currentUser: JwtData): boolean {
    const currentTimestamp = moment.utc().valueOf() / 1000;

    return (
      !currentUser.expirationTimestamp ||
      currentTimestamp <= currentUser.expirationTimestamp
    );
  }

  private logExpiredToken(currentUser: JwtData): void {
    const expirationTimestamp = currentUser.expirationTimestamp * 1000;
    if (expirationTimestamp !== this._lastJwtExpirationTime) {
      this._logger.info(
        `JWT expired: ${moment.utc(expirationTimestamp).toLocaleString()}.`
      );
      this._lastJwtExpirationTime = expirationTimestamp;
    }
  }
}

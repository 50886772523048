import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestaurantRole } from 'src/app/models/enums';
import { JwtData } from 'src/app/models/jwt-data';
import { LoginRequest } from 'src/app/models/requests/login-request';
import { RegisterRequest } from 'src/app/models/requests/register-request';
import { ResetPasswordRequest } from 'src/app/models/requests/reset-password-request';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private static apiUrl = 'authn';

  constructor(private _clientApiService: ClientApiService) {}

  public registerEmail(email: string): Observable<void> {
    return this._clientApiService.post<void>(
      `${AuthApiService.apiUrl}/register/request`,
      email
    );
  }

  public registerPassword(
    registerRequest: RegisterRequest
  ): Observable<string> {
    return this._clientApiService.post<string>(
      `${AuthApiService.apiUrl}/register`,
      registerRequest
    );
  }

  public login(loginRequest: LoginRequest): Observable<JwtData> {
    return this._clientApiService.post(
      `${AuthApiService.apiUrl}/login`,
      loginRequest
    );
  }

  public loginRole(
    restaurantId: number,
    role: RestaurantRole
  ): Observable<JwtData> {
    console.log(`loginRole | ${restaurantId} | ${role}`);
    const restaurantIdPath = restaurantId ? `?rid=${restaurantId}` : '';
    return this._clientApiService.get(
      `${AuthApiService.apiUrl}/login/${role}${restaurantIdPath}`
    );
  }

  public forgotPassword(email: string): Observable<void> {
    return this._clientApiService.post(
      `${AuthApiService.apiUrl}/password/reset/request`,
      email
    );
  }

  public resetPassword(
    resetPasswordRequest: ResetPasswordRequest
  ): Observable<void> {
    return this._clientApiService.post(
      `${AuthApiService.apiUrl}/password/reset`,
      resetPasswordRequest
    );
  }

  public changePasswordRequest(email: string): Observable<string> {
    return this._clientApiService.postAndGetString(
      `${AuthApiService.apiUrl}/password/reset/request`,
      email
    );
  }

  public changeEmailRequest(email: string): Observable<string> {
    return this._clientApiService.postAndGetString(
      `${AuthApiService.apiUrl}/email/change/request`,
      email
    );
  }

  public acceptNewEmail(jwt: string): Observable<string> {
    return this._clientApiService.postAndGetString(
      `${AuthApiService.apiUrl}/email/change`,
      jwt
    );
  }

  public renewJwt(): Observable<JwtData> {
    return this._clientApiService.get(`${AuthApiService.apiUrl}/renew/jwt`);
  }

  public loginClient(): Observable<JwtData> {
    return this._clientApiService.get(`${AuthApiService.apiUrl}/login/client`);
  }

  public check(): Observable<void> {
    return this._clientApiService.get<void>(`${AuthApiService.apiUrl}/check`);
  }

  public checkClient(): Observable<void> {
    return this._clientApiService.get<void>(
      `${AuthApiService.apiUrl}/check/client`
    );
  }
}

import { LogLevel } from 'src/app/models/enums/log-level';
import { LoggingService } from './logging.service';

export class Logger {
  constructor(
    private _loggingService: LoggingService,
    private _className: string
  ) {}

  public debug(...params: any): void {
    this._loggingService.logMessage(LogLevel.Debug, this._className, ...params);
  }

  public info(...params: any): void {
    this._loggingService.logMessage(LogLevel.Info, this._className, ...params);
  }

  public warn(...params: any): void {
    this._loggingService.logMessage(LogLevel.Warn, this._className, ...params);
  }

  public error(...params: any): void {
    this._loggingService.logMessage(LogLevel.Error, this._className, ...params);
  }
}

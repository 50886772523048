import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth', // TODO: change to om when is done
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'om',
    loadChildren: () =>
      import('./modules/core/online-menu.module').then(
        (m) => m.OnlineMenuComponentModule
      ),
  },
  {
    path: 'rest',
    loadChildren: () =>
      import('./modules/client/restaurant.module').then(
        (m) => m.RestaurantComponentModule
      ),
  },
  // {
  //   path: 'management',
  //   loadChildren: () =>
  //     import('./modules/management/management.component').then(
  //       (m) => m.ManagementComponent
  //     ),
  //   canActivate: [authGuard],
  // },
  {
    path: 'waiter',
    loadChildren: () =>
      import('./modules/waiter/waiter.module').then(
        (m) => m.WaiterComponentModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'cook',
    loadChildren: () =>
      import('./modules/cook/cook.module').then((m) => m.CookComponentModule),
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { ErrorCode } from 'src/app/models/enums/error-code';
import { AuthApiService } from 'src/app/services/api/auth-api.service';
import { ClientService } from 'src/app/services/client/client.service';
import { LocalStorageService } from 'src/app/services/common/local-storage/local-storage.service';
import { Logger } from 'src/app/services/common/logging/logger';
import { LoggerService } from 'src/app/services/common/logging/logger.service';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _logger: Logger;

  constructor(
    private _loggerService: LoggerService,
    private _router: Router,
    private _authApiService: AuthApiService,
    private _clientService: ClientService,
    private _localStorageService: LocalStorageService
  ) {
    this._logger = this._loggerService.getService(ErrorInterceptor.name);
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleErrorStatusCode401(error, request, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handleErrorStatusCode401(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (error.error === ErrorCode.NoClientJwt) {
      this._logger.info(
        'Current client is not logged in, start the logging process.'
      );
      return this.handleNoClientJwt(request, next);
    }

    return this.handleUserNotLogged(error);
  }

  private handleNoClientJwt(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._authApiService.loginClient().pipe(
      switchMap((data) => {
        this._logger.info(
          'Client was logged successfully, check if JWT was locally store.'
        );
        this._localStorageService.saveData(
          LocalStorageKeys.CurrentClient,
          JSON.stringify(data)
        );

        return this._authApiService.checkClient().pipe(
          switchMap(() => {
            this._logger.info('Check of the client JWT was successfully.');
            return next.handle(request);
          }),
          catchError((checkError: any) => {
            this._logger.error(
              'Client JWT was not setted, show the client missing JWT popup.'
            );
            this._clientService.showClientPopup();
            return throwError(() => checkError);
          })
        );
      }),
      catchError((loginError: any) => {
        this._logger.error('Could not log the client: ', loginError);
        return throwError(() => loginError);
      })
    );
  }

  private handleUserNotLogged(
    error: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    this._logger.info(
      `Received error <${error.error}>, logout the current user if exist and navigate to login page.`
    );
    this.logoutUser();

    return throwError(() => error);
  }

  private logoutUser(): void {
    this._localStorageService.removeData(LocalStorageKeys.CurrentUser);
    this._localStorageService.removeData(LocalStorageKeys.CurrentClient);
    this._router.navigate(['auth', 'login']);
  }
}

import { Injectable } from '@angular/core';
import { LogLevel } from 'src/app/models/enums/log-level';
import { LoggingApiService } from '../../api/logging-api.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private _loggingApiService: LoggingApiService) {}

  public logMessage(
    logLevel: LogLevel,
    className: string,
    ...params: any
  ): void {
    const message = this.prepareMessage(className, params);
    this._loggingApiService.logMessage(logLevel, message);
    console.log(message);
  }

  private prepareMessage(className: string, ...params: any): string {
    let message = `[${className}]: `;

    for (let param of params) {
      message += this.convertParameterToString(param);
    }

    return message;
  }

  private convertParameterToString(param: any): string {
    if (Array.isArray(param)) {
      let message = '';
      for (let item of param) {
        message += this.convertParameterToString(item);
      }

      return message;
    }

    if (typeof param === 'object' && param !== null) {
      return JSON.stringify(param);
    }

    return param;
  }
}

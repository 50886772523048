import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientApiService {
  constructor(private _http: HttpClient) {}

  public get<T>(url: string): Observable<T> {
    return this._http.get<T>(`${environment.clientApi}/${url}`).pipe(take(1));
  }

  public post<T>(url: string, data: any): Observable<T> {
    return this._http
      .post<T>(`${environment.clientApi}/${url}`, data)
      .pipe(take(1));
  }

  public postAndGetString(url: string, data: any): Observable<string> {
    return this._http
      .post(`${environment.clientApi}/${url}`, data, {
        responseType: 'text',
      })
      .pipe(take(1));
  }

  public put<T>(url: string, data: any): Observable<T> {
    return this._http
      .put<T>(`${environment.clientApi}/${url}`, data)
      .pipe(take(1));
  }

  public delete(url: string, options?: any): Observable<any> {
    return this._http
      .delete<string>(`${environment.clientApi}/${url}`, options)
      .pipe(take(1));
  }
}

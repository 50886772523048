import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/common/translation/translation.service';

@Pipe({
  name: 'translate',
  standalone: true,
})
export class TranslationPipe implements PipeTransform, OnDestroy {
  private _subscription: Subscription;

  constructor(private _translationService: TranslationService) {}

  public transform(key: string, ...params: any[]): Observable<string> {
    this.unsubscribeAll();

    return new Observable((observer) => {
      this._translationService.ready.then(() => {
        this._subscription = this._translationService.languageChange.subscribe(
          async () => {
            const translation = await this._translationService.translate(
              key,
              params
            );
            observer.next(translation);
          }
        );
      });
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private unsubscribeAll(): void {
    this._subscription?.unsubscribe();
  }
}

export class TranslationKey {
  public static RestaurantName = 'RESTAURANT.NAME';
  public static RestaurantDescription = 'RESTAURANT.DESCRIPTION';
  public static TableName = 'TABLE.NAME';
  public static MenuName = 'MENU.NAME';
  public static ProductName = 'PRODUCT.NAME';
  public static ProductDescription = 'PRODUCT.DESCRIPTION';
  public static IngredientName = 'INGREDIENT.NAME';
  public static AllergenName = 'ALLERGEN.NAME';
}

/*
To build your application with a specific environment, you can use the --configuration flag
with the Angular CLI ng build command. For example, to build for production, you can run:
ng build --configuration=production
*/

export const environment = {
  socketUrl: 'ws://managemenu.space/socket.io',
  clientApi: 'http://managemenu.space/api',
  clientUrl: 'http://managemenu.space',
  countryDefaultLanguage: 'EN',
  basketExpirationTimeInMinutes: 180,
  callWaiterActionTimeoutInSeconds: 10,
  payBillActionsTimeoutInSeconds: 10,
  renewJwtTimeoutInSeconds: 1800,
  restaurantStartUrl: '/rest',
  localStorageEncryptionKey: 'ab6abcae-bfac-4cc6-9ca5-47130616909e',
};

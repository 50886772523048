import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationPipe } from 'src/app/shared/pipes/translation.pipe';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslationPipe],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
})
export class NotificationModule {}

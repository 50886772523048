import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OmMissingTranslationHandler extends MissingTranslationHandler {
  public override handle(params: MissingTranslationHandlerParams) {
    if (!Object.keys(params?.translateService?.translations).length) {
      // translations are not loaded yet
      return '';
    }

    return this.getTranslationForKey(params);
  }

  private getTranslationForKey(
    params: MissingTranslationHandlerParams
  ): string {
    const defaultLanguage = environment.countryDefaultLanguage;
    const translation =
      params.translateService.translations[defaultLanguage][params.key];

    return translation || params.key;
  }
}

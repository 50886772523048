import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Notification, NotificationType } from './notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notifications = new Subject<Notification>();
  private _idx = 0;

  public notifications(): Observable<Notification> {
    return this._notifications.asObservable();
  }

  public info(
    message: string,
    title: string = 'NOTIFICATION.INFO',
    timeout = 10000
  ): void {
    this._notifications.next(
      new Notification(
        this._idx++,
        NotificationType.Info,
        title,
        message,
        timeout
      )
    );
  }

  public success(
    message: string,
    title: string = 'NOTIFICATION.SUCCESS',
    timeout = 10000
  ): void {
    this._notifications.next(
      new Notification(
        this._idx++,
        NotificationType.Success,
        title,
        message,
        timeout
      )
    );
  }

  public warning(
    message: string,
    title: string = 'NOTIFICATION.WARNING',
    timeout = 10000
  ): void {
    this._notifications.next(
      new Notification(
        this._idx++,
        NotificationType.Warning,
        title,
        message,
        timeout
      )
    );
  }

  public error(
    message: string,
    title: string = 'NOTIFICATION.ERROR',
    timeout = 10000
  ): void {
    this._notifications.next(
      new Notification(
        this._idx++,
        NotificationType.Error,
        title,
        message,
        timeout
      )
    );
  }
}

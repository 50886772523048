import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, filter, firstValueFrom } from 'rxjs';
import { RestaurantMetadata } from 'src/app/models/client/restaurant-metadata';
import { TextValue } from 'src/app/models/text-value';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';
import { TranslationKey } from 'src/app/utils/constants/translation-key';
import { environment } from 'src/environments/environment';
import { ClientApiService } from '../../api/client-api.service';
import { RestaurantApiService } from '../../api/restaurant-api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _ready: Promise<void>;
  private _languages: string[] = [];
  private _languageChange = new BehaviorSubject<string>(
    environment.countryDefaultLanguage
  );
  private _defaultLanguage = environment.countryDefaultLanguage;

  constructor(
    private _router: Router,
    private _translateService: TranslateService,
    private _localStorageService: LocalStorageService,
    private _clientApiService: ClientApiService,
    private _restaurantApiService: RestaurantApiService
  ) {
    this.init();
  }

  public get ready(): Promise<void> {
    return this._ready;
  }

  public get languageChange(): Observable<string> {
    return this._languageChange.asObservable();
  }

  public get languages(): string[] {
    return this._languages;
  }

  public get language(): string {
    return this._defaultLanguage;
  }

  private init(): void {
    this._ready = this.initialize();
  }

  private async initialize(): Promise<void> {
    const currentUrl = await this.getCurrentUrl();
    if (!currentUrl) return;

    if (currentUrl.startsWith('/rest')) {
      return this.initTranslationForRestaurant(currentUrl);
    } else {
      return this.initTranslationForApplication();
    }
  }

  private async getCurrentUrl(): Promise<string> {
    const event = await firstValueFrom(
      this._router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      )
    );

    return (event as any)?.url;
  }

  private async initTranslationForRestaurant(
    currentUrl: string
  ): Promise<void> {
    const urlParts = currentUrl.split('?t=');
    if (urlParts.length < 2 || isNaN(+urlParts[1])) return;

    const restaurantMetadata = await this.getRestaurantMetadata(+urlParts[1]);
    const supportedLanguages =
      restaurantMetadata.supportedLanguages.split(', ');
    this.initTranslation(
      supportedLanguages,
      restaurantMetadata.deafultLanguage
    );
  }

  private getRestaurantMetadata(tableId: number): Promise<RestaurantMetadata> {
    return firstValueFrom(
      this._restaurantApiService.readMetadataByTableId(tableId)
    );
  }

  private async initTranslationForApplication(): Promise<void> {
    const languages = await firstValueFrom(
      this._clientApiService.get<string[]>('lang/read/all')
    );
    this.initTranslation(languages, environment.countryDefaultLanguage);
  }

  private initTranslation(languages: string[], language: string) {
    this._languages = languages;
    this._defaultLanguage = language;
    const localStorageLanguage =
      this.getUserLanguageFromLocalStorage(languages);
    const defaultLanguage = localStorageLanguage ?? this._defaultLanguage;

    this._translateService.addLangs(languages);
    this._translateService.use(defaultLanguage);
    this._languageChange.next(defaultLanguage);
  }

  private getUserLanguageFromLocalStorage(
    languages: string[]
  ): string | undefined {
    const selectedLanguage = this._localStorageService.getData(
      LocalStorageKeys.DefaultLanguage
    );
    if (selectedLanguage && languages.includes(selectedLanguage)) {
      return selectedLanguage;
    }

    return undefined;
  }

  public changeLanguage(language: string): void {
    const newLanguage = language.toLocaleUpperCase();
    this._localStorageService.saveData(
      LocalStorageKeys.DefaultLanguage,
      newLanguage
    );
    this._translateService.use(newLanguage);
    this._languageChange.next(newLanguage);
  }

  public translate(key: string, params?: object): Promise<string> {
    return firstValueFrom(this._translateService.get(key, params));
  }

  public getRestaurantName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.RestaurantName);
  }

  public getRestaurantDescription(textValues: TextValue[]): string | undefined {
    return this.getTranslation(
      textValues,
      TranslationKey.RestaurantDescription
    );
  }

  public getMenuName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.MenuName);
  }

  public getTableName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.TableName);
  }

  public getProductName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.ProductName);
  }

  public getProductDescription(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.ProductDescription);
  }

  public getIngredientName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.IngredientName);
  }

  public getAllergenName(textValues: TextValue[]): string | undefined {
    return this.getTranslation(textValues, TranslationKey.AllergenName);
  }

  private getTranslation(
    textValues: TextValue[],
    key: string
  ): string | undefined {
    return this.getTranslationForLanguage(
      textValues,
      key,
      this._languageChange.getValue()
    );
  }

  public getTranslationForLanguage(
    textValues: TextValue[],
    key: string,
    language: string
  ): string | undefined {
    let initialValue = textValues?.find(
      (x) => x.key === key && x.lang === language
    )?.value;

    if (initialValue || !this._defaultLanguage) return initialValue;

    return textValues.find(
      (x) => x.key === key && x.lang === this._defaultLanguage
    )?.value;
  }
}

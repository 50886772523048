import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/common/notification/notification.service';
import {
  Notification,
  NotificationType,
} from 'src/app/services/common/notification/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public _notifications: Notification[] = [];
  private _notificationsSubscription: Subscription;

  constructor(private _notificationService: NotificationService) {}

  public get notifications(): Notification[] {
    return this._notifications;
  }

  public ngOnInit(): void {
    this._notificationsSubscription = this._notificationService
      .notifications()
      .subscribe((notification) => this.addNotification(notification));
  }

  private addNotification(notification: Notification) {
    this._notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  public close(notification: Notification) {
    this._notifications = this.notifications.filter(
      (notif) => notif.id !== notification.id
    );
  }

  public getClassName(notification: Notification): string {
    switch (notification.type) {
      case NotificationType.Success:
        return 'success';

      case NotificationType.Warning:
        return 'warning';

      case NotificationType.Error:
        return 'error';

      default:
        return 'info';
    }
  }

  public ngOnDestroy(): void {
    this._notificationsSubscription?.unsubscribe();
  }
}

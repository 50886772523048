import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  RestaurantData,
  RestaurantMetadata,
} from 'src/app/models/client/restaurant-metadata';
import { LocalStorageKeys } from 'src/app/utils/constants/local-storage';
import { RestaurantApiService } from '../api/restaurant-api.service';
import { LocalStorageService } from '../common/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private _tableId = new BehaviorSubject<number>(-1);
  private _restaurantMetadata = new BehaviorSubject<RestaurantData | undefined>(
    undefined
  );
  private _clientPopup = new BehaviorSubject<boolean>(false);

  constructor(
    private _restaurantApiService: RestaurantApiService,
    private _localStorageService: LocalStorageService
  ) {}

  public get tableId(): Observable<number> {
    return this._tableId.asObservable();
  }

  public get restaurantMetadata(): Observable<RestaurantData | undefined> {
    return this._restaurantMetadata.asObservable();
  }

  public get clientPopup(): Observable<boolean> {
    return this._clientPopup.asObservable();
  }

  public setTableId(tableId: number) {
    this._tableId.next(tableId);
    this.getRestaurantMetadata(tableId);
  }

  private getRestaurantMetadata(tableId: number): void {
    this._restaurantApiService.readMetadataByTableId(tableId).subscribe({
      next: (data) => this.onRestaurantMetadataReceived(tableId, data),
      error: (error) =>
        console.error(
          `Could not get restaurant metadata for tableId ${tableId}: `,
          error
        ),
    });
  }

  private onRestaurantMetadataReceived(
    tableId: number,
    data: RestaurantMetadata
  ): void {
    if (!data) return;

    this.updateRestaurantId(data.restaurantId);
    this.updateRestaurantMetadata(tableId, data);
  }

  private updateRestaurantId(restaurantId: number): void {
    const localRestaurantId = this._localStorageService.getData(
      LocalStorageKeys.CurrentRestaurantId
    );
    this._localStorageService.saveData(
      LocalStorageKeys.CurrentRestaurantId,
      `${restaurantId}`
    );

    if (!localRestaurantId || isNaN(+localRestaurantId)) return;

    if (+localRestaurantId !== restaurantId) {
      this._localStorageService.removeData(LocalStorageKeys.CurrentClient);
    }
  }

  private updateRestaurantMetadata(
    tableId: number,
    data: RestaurantMetadata
  ): void {
    this._restaurantMetadata.next({
      id: data.restaurantId,
      tableId,
      currency: data.currency,
      deafultLanguage: data.deafultLanguage,
      supportedLanguages: data.supportedLanguages.split(', '),
    });
  }

  public showClientPopup(): void {
    this._clientPopup.next(true);
  }

  public warningAccepted(): void {
    this._clientPopup.next(false);
  }
}

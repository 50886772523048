import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountShort } from 'src/app/models/entities/account-short';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  private static apiUrl = 'account';

  constructor(private _clientApiService: ClientApiService) {}

  public readByRestaurantId(restaurantId: number): Observable<AccountShort[]> {
    return this._clientApiService.post<AccountShort[]>(
      `${AccountApiService.apiUrl}/read/restaurant`,
      { id: restaurantId }
    );
  }

  public read(): Observable<AccountShort> {
    return this._clientApiService.post<AccountShort>(
      `${AccountApiService.apiUrl}/read`,
      ''
    );
  }
}
